body {
     padding: 0;
     color: rgba(0, 0, 0, 0.65);
     font-size: 14px;
     line-height: 1.6;
     font-weight: 400;
     font-family: open sans, Helvetica, Arial, sans-serif;
}

// #root {
//      height: 100vh;
// }
@primary-color: #64956D;@link-color: #1890ff;@success-color: #ff0000;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: #000;@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);